import clsx from "clsx";
import React from "react";
import type { IItemProps } from "react-movable";
import TextareaAutosize from "react-textarea-autosize";

import { makeStyles } from "../styles/makeStyles";
import { delay } from "../util/delay";

const inputAutoFillClasses =
    "input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active";

const useStyles = makeStyles<{ includeLabel: boolean; inputType: CymbalInputType }>()(
    (theme, { includeLabel, inputType }) => ({
        outerWrapper: {
            "&:focus": {
                outline: "none",
            },
        },
        inputWrapper: {
            width: "100%",
            position: "relative",
            display: "flex",
            lineHeight: 1,
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
            },
            "input[type=number]": {
                MozAppearance: "textfield",
            },
            [`${inputAutoFillClasses}`]: {
                transition: "background-color 5000s ease-in-out 0s",
                WebkitTextFillColor: theme.palette.text.primary,
            },
            borderRadius: theme.spacing(1),
            backgroundColor:
                inputType === "filled"
                    ? theme.palette.background.paper
                    : inputType === "filled-highlight"
                      ? theme.palette.background.paperHighlight
                      : inputType === "filled-double-highlight"
                        ? theme.palette.background.paperDoubleHighlight
                        : inputType === "accent-highlight"
                          ? theme.palette.background.accentHighlight
                          : undefined,
            cursor: "text",
            "&.disabled": {
                pointerEvents: "none",
                userSelect: "none",
                ...(inputType !== "outlined"
                    ? {
                          opacity: 0.5,
                          color: inputType === "accent-highlight" ? theme.palette.accentGray : theme.palette.midGray,
                          [`${inputAutoFillClasses}`]: {
                              WebkitTextFillColor:
                                  inputType === "accent-highlight" ? theme.palette.accentGray : theme.palette.midGray,
                          },
                      }
                    : {}),
            },
        },
        fullWidth: {
            width: "100%",
        },
        textarea: {
            overflow: "hidden",
            resize: "none",
            lineHeight: 1.5,
        },
        inputAndStartText: {
            display: "flex",
            alignItems: "flex-end",
            lineHeight: 1.5,
        },
        startText: {
            paddingBottom: 12,
            lineHeight: 1.5,
            marginRight: theme.spacing(1),
            fontWeight: 300,
        },
        input: {
            fontFamily: "Lexend",
            border: "none",
            background: "none",
            width: "100%",
            fontWeight: 300,
            fontSize: "1rem",
            margin: 0,
            lineHeight: 1.5,
            "&::placeholder": {
                color: inputType === "accent-highlight" ? theme.palette.accentGray : theme.palette.midGray,
            },
            "&:focus": {
                outline: "none",
            },
        },
        inputNoBottomAdornment: {
            padding: includeLabel ? "8px 0px 12px" : "23px 0px 23px",
        },
        inputBottomAdornment: {
            padding: includeLabel ? "8px 0px 4px" : "23px 0px 15px",
        },
        inputDisabled: {
            color: theme.palette.midGray,
        },
        inputNotDisabled: {
            color: theme.palette.text.primary,
        },
        fieldSetDisabled: {
            border: `1px solid ${theme.palette.divider}`,
        },
        fieldSetStandard: {
            border: `1px solid ${theme.palette.midGray}`,
        },
        fieldSetFocused: {
            border: `1px solid ${theme.palette.text.primary}`,
        },
        fieldSetError: {
            border: `1px solid ${theme.palette.error.main}`,
        },
        labelInputSection: {
            width: "100%",
        },
        labelContainer: {
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            top: -2,
        },
        label: {
            height: "fit-content",
            fontSize: "1rem",
            color: inputType === "accent-highlight" ? theme.palette.accentGray : theme.palette.midGray,
            transition: "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
            transformOrigin: "left",
            overflow: "hidden",
            lineHeight: 1.5,
            zIndex: 1,
            pointerEvents: "none",
        },
        labelRight: {
            textAlign: "right",
            transformOrigin: "right !important",
        },
        labelUnraised: {
            transform: "translateY(23px) scale(1)",
            width: "100%",
        },
        labelRaised: {
            transform: "translateY(10px) scale(0.75)",
        },
        bottomText: {
            paddingBottom: 12,
            fontSize: "0.875rem",
            fontWeight: 300,
            lineHeight: 1.5,
        },
        bottomTextDisabled: {
            color: theme.palette.midGray,
        },
        startAdornment: {
            display: "flex",
            alignItems: "center",
            borderBottomLeftRadius: theme.spacing(1),
            borderTopLeftRadius: theme.spacing(1),
        },
        startAdornmentPadding: {
            padding: "0 12px 0 16px",
            "&.empty": {
                paddingRight: 0,
            },
        },
        endAdornment: {
            borderTopRightRadius: theme.spacing(1),
            borderBottomRightRadius: theme.spacing(1),
            display: "flex",
            alignItems: "center",
            padding: "0 16px 0 12px",
            "&.empty": {
                paddingLeft: 0,
            },
        },
        fieldset: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 0,
            padding: 0,
            pointerEvents: "none",
        },
        fieldsetBorderRadius: {
            borderRadius: theme.spacing(1),
        },
        errorText: {
            fontSize: "0.75rem",
            color: theme.palette.error.main,
            marginLeft: theme.spacing(0.5),
            marginTop: theme.spacing(1.5),
        },
        overlaySection: {
            position: "absolute",
            top: 0,
            pointerEvents: "none",
            opacity: 0.5,
        },
    }),
);

export type CymbalInputType =
    | "outlined"
    | "filled"
    | "filled-highlight"
    | "filled-double-highlight"
    | "accent-highlight";

interface BaseProps {
    className?: string;
    wrapperClassName?: string;
    fieldsetClassName?: string;
    inputClassName?: string;
    renderItemProps?: IItemProps;
    fullWidth?: boolean;
    label?: string;
    labelRight?: string;
    value?: string | number;
    valueEnd?: string;
    defaultValue?: string | number;
    type?: React.HTMLInputTypeAttribute;
    isDisabled?: boolean;
    isError?: boolean;
    errorText?: string;
    autoComplete?: string;
    placeholder?: string;
    startText?: string;
    StartAdornment?: React.ReactNode;
    EndAdornment?: React.ReactNode;
    endAdornmentClassName?: string;
    inputElementClassName?: string;
    BottomAdornment?: React.ReactNode;
    autoFocus?: boolean;
    inputType?: CymbalInputType;
    noStartAdornmentPadding?: boolean;
    noEndAdornmentPadding?: boolean;
    textArea?: boolean;
    containerRef?: React.RefObject<HTMLDivElement>;
    isFocusedOverride?: boolean;
    hasValueOverride?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onSelect?: React.ReactEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

interface InputProps extends BaseProps {
    min?: number | string;
    max?: number | string;
    inputRef?: React.RefObject<HTMLInputElement>;
    maxLength?: number;
}

interface CustomInputProps extends BaseProps {
    CustomInput: React.ReactNode;
}

export interface CymbalTextAreaProps extends BaseProps {
    minRows?: number;
    maxRows?: number;
    textAreaRef?: React.RefObject<HTMLTextAreaElement>;
    OverlaySection?: React.ReactNode;
    value: string;
}

const isTextAreaProps = (props: CymbalInputProps): props is CymbalTextAreaProps =>
    (props as CymbalTextAreaProps).textArea === true;

const isCustomInputProps = (props: CymbalInputProps): props is CustomInputProps =>
    (props as CustomInputProps).CustomInput !== undefined;

export type CymbalInputProps = CymbalTextAreaProps | InputProps | CustomInputProps;

export const CymbalInput = React.forwardRef<HTMLDivElement, CymbalInputProps>((props, ref) => {
    const {
        className,
        renderItemProps,
        wrapperClassName,
        fieldsetClassName,
        inputClassName,
        fullWidth = false,
        label,
        labelRight,
        value,
        valueEnd,
        defaultValue,
        type,
        isDisabled = false,
        isError = false,
        errorText,
        autoComplete,
        BottomAdornment,
        placeholder,
        startText,
        StartAdornment,
        EndAdornment,
        endAdornmentClassName,
        inputElementClassName,
        noStartAdornmentPadding = false,
        noEndAdornmentPadding = false,
        autoFocus,
        isFocusedOverride,
        hasValueOverride,
        containerRef,
        inputType = "outlined",
        onClick,
        onMouseDown,
        onMouseEnter,
        onMouseLeave,
        onChange,
        onFocus,
        onBlur,
        onKeyDown,
    } = props;

    const { classes } = useStyles({ inputType, includeLabel: !!label || !!labelRight });

    const [isFocused, setIsFocused] = React.useState(isFocusedOverride ?? false);
    const [isTriggeringTextArea, setIsTriggeringTextArea] = React.useState(false);
    const [hasTriggeredTextArea, setHasTriggeredTextArea] = React.useState(false);

    React.useEffect(() => {
        if (isFocusedOverride !== undefined) {
            setIsFocused(isFocusedOverride);
        }
    }, [isFocusedOverride]);

    const localInputRef = React.createRef<HTMLInputElement>();
    const inputRef = isTextAreaProps(props)
        ? undefined
        : isCustomInputProps(props)
          ? undefined
          : props.inputRef ?? localInputRef;

    const localTextAreaRef = React.createRef<HTMLTextAreaElement>();
    const textAreaRef = isTextAreaProps(props) ? props.textAreaRef ?? localTextAreaRef : undefined;

    const textArea = isTextAreaProps(props);

    React.useEffect(() => {
        if (textArea && !hasTriggeredTextArea) {
            delay(50).then(() => setIsTriggeringTextArea(true));
        }
    }, [textArea, hasTriggeredTextArea]);

    React.useEffect(() => {
        if (isTriggeringTextArea) {
            setIsTriggeringTextArea(false);
            setHasTriggeredTextArea(true);
        }
    }, [isTriggeringTextArea]);

    React.useEffect(() => {
        if (autoFocus) {
            inputRef?.current?.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const displayValue = !valueEnd || value === undefined || value === "" ? value : `${value}${valueEnd}`;
    const displayValueRef = React.useRef(displayValue);
    React.useEffect(() => {
        if (
            displayValue !== displayValueRef.current &&
            !!inputRef?.current &&
            typeof displayValue === "string" &&
            !!displayValue &&
            !!valueEnd
        ) {
            const maxSelection = displayValue.replace(valueEnd, "").length;
            if (inputRef?.current.selectionStart !== null && inputRef?.current.selectionStart > maxSelection) {
                inputRef?.current.setSelectionRange(maxSelection, maxSelection);
            } else if (inputRef?.current.selectionEnd !== null && inputRef?.current.selectionEnd > maxSelection) {
                inputRef?.current.setSelectionRange(
                    Math.min(inputRef?.current.selectionStart ?? 0, maxSelection),
                    maxSelection,
                );
            }
        }

        displayValueRef.current = displayValue;
    }, [displayValue, valueEnd, inputRef]);

    const hasValue = hasValueOverride
        ? hasValueOverride
        : (value !== undefined && value !== "") ||
          (defaultValue !== undefined && defaultValue !== "" && value === undefined && onChange === undefined);

    const handleFocus = React.useCallback(
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setIsFocused(true);
            if (onFocus) {
                onFocus(e);
            }
        },
        [onFocus],
    );

    const handleBlur = React.useCallback(
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setIsFocused(false);
            if (onBlur) {
                onBlur(e);
            }
        },
        [onBlur],
    );

    return (
        <div
            ref={containerRef}
            className={clsx(className, classes.outerWrapper, fullWidth ? classes.fullWidth : undefined)}
            {...renderItemProps}
        >
            <div
                className={clsx(wrapperClassName, classes.inputWrapper, isDisabled ? "disabled" : undefined)}
                ref={ref}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <div
                    className={classes.startAdornment}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        inputRef?.current?.focus();
                        inputRef?.current?.setSelectionRange(0, 0);
                    }}
                >
                    <div
                        className={clsx(
                            classes.startAdornmentPadding,
                            !StartAdornment || noStartAdornmentPadding ? "empty" : undefined,
                        )}
                    >
                        {StartAdornment}
                    </div>
                </div>

                <div
                    className={classes.labelInputSection}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        inputRef?.current?.focus();
                    }}
                >
                    {(!!label || !!labelRight) && (
                        <div className={classes.labelContainer}>
                            {!!label && (
                                <div
                                    className={clsx(
                                        classes.label,
                                        hasValue || isFocused ? classes.labelRaised : classes.labelUnraised,
                                    )}
                                >
                                    {label}
                                </div>
                            )}
                            {!!labelRight && (
                                <div
                                    className={clsx(
                                        classes.label,
                                        classes.labelRight,
                                        hasValue || isFocused ? classes.labelRaised : classes.labelUnraised,
                                    )}
                                >
                                    {labelRight}
                                </div>
                            )}
                        </div>
                    )}
                    {isCustomInputProps(props) ? (
                        props.CustomInput
                    ) : isTextAreaProps(props) ? (
                        <div style={{ position: "relative" }}>
                            <TextareaAutosize
                                className={clsx(
                                    classes.textarea,
                                    classes.input,
                                    isDisabled ? classes.inputDisabled : classes.inputNotDisabled,
                                    !!BottomAdornment ? classes.inputBottomAdornment : classes.inputNoBottomAdornment,
                                    inputClassName,
                                )}
                                value={isTriggeringTextArea ? `${value} ` : value}
                                defaultValue={defaultValue}
                                autoComplete={autoComplete}
                                placeholder={!!value || isFocused || !label ? placeholder : undefined}
                                minRows={props.minRows}
                                maxRows={props.maxRows}
                                onChange={onChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyDown={onKeyDown}
                                ref={textAreaRef}
                                onSelect={props.onSelect}
                            />
                            {props.OverlaySection && (
                                <div
                                    className={clsx(
                                        classes.textarea,
                                        classes.overlaySection,
                                        classes.input,
                                        isDisabled ? classes.inputDisabled : classes.inputNotDisabled,
                                        !!BottomAdornment
                                            ? classes.inputBottomAdornment
                                            : classes.inputNoBottomAdornment,
                                        inputClassName,
                                    )}
                                >
                                    {props.OverlaySection}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={clsx(classes.inputAndStartText, inputClassName)}>
                            {startText && (value || isFocused) && <div className={classes.startText}>{startText}</div>}
                            <input
                                ref={inputRef}
                                className={clsx(
                                    classes.input,
                                    isDisabled ? classes.inputDisabled : classes.inputNotDisabled,
                                    !!BottomAdornment ? classes.inputBottomAdornment : classes.inputNoBottomAdornment,
                                    inputElementClassName,
                                )}
                                value={displayValue}
                                defaultValue={defaultValue}
                                type={type}
                                autoComplete={autoComplete}
                                placeholder={value || isFocused || !label ? placeholder : undefined}
                                min={props.min}
                                max={props.max}
                                onChange={
                                    !!onChange
                                        ? (e) => {
                                              if (!valueEnd) {
                                                  onChange(e);
                                              } else {
                                                  const newValue =
                                                      value === undefined || value === ""
                                                          ? e.target.value
                                                          : e.target.value.slice(
                                                                0,
                                                                e.target.value.length - valueEnd.length,
                                                            );

                                                  onChange({
                                                      ...e,
                                                      target: {
                                                          ...e.target,
                                                          value: newValue,
                                                      },
                                                  });
                                              }
                                          }
                                        : undefined
                                }
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyDown={onKeyDown}
                                onSelect={(e) => {
                                    if (!!props.onSelect) {
                                        props.onSelect(e);
                                        return;
                                    }

                                    if (
                                        !!valueEnd &&
                                        e.currentTarget.selectionEnd !== null &&
                                        e.currentTarget.selectionEnd > e.currentTarget.value.length - valueEnd.length
                                    ) {
                                        e.currentTarget.setSelectionRange(
                                            e.currentTarget.selectionStart ?? e.currentTarget.selectionEnd,
                                            e.currentTarget.value.length - valueEnd.length,
                                        );
                                    }
                                }}
                                maxLength={props.maxLength}
                            />
                        </div>
                    )}
                    {!!BottomAdornment && (
                        <div
                            className={clsx(classes.bottomText, isDisabled ? classes.bottomTextDisabled : undefined)}
                            onMouseDown={(e) => {
                                e.preventDefault();
                                inputRef?.current?.focus();
                            }}
                        >
                            {BottomAdornment}
                        </div>
                    )}
                </div>
                <div
                    className={clsx(
                        classes.endAdornment,
                        !EndAdornment || noEndAdornmentPadding ? "empty" : undefined,
                        endAdornmentClassName,
                    )}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        inputRef?.current?.focus();
                    }}
                >
                    {EndAdornment}
                </div>
                {(inputType === "outlined" || isError) && (
                    <fieldset
                        className={clsx(
                            fieldsetClassName ?? classes.fieldsetBorderRadius,
                            classes.fieldset,
                            isDisabled
                                ? classes.fieldSetDisabled
                                : isError
                                  ? classes.fieldSetError
                                  : isFocused
                                    ? classes.fieldSetFocused
                                    : fieldsetClassName ?? classes.fieldSetStandard,
                        )}
                    />
                )}
            </div>
            {isError && errorText && <div className={classes.errorText}>{errorText}</div>}
        </div>
    );
});
